// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-clients-js": () => import("./../../src/templates/clients.js" /* webpackChunkName: "component---src-templates-clients-js" */),
  "component---src-templates-contact-js": () => import("./../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-services-js": () => import("./../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

