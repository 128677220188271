module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130496779-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1858977507554551"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Warsaw Production Team","short_name":"WPT","start_url":"/","background_color":"#cd9164","theme_color":"#cd9164","icon":"src/assets/images/logo512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"409f6e56157cfa41061aeb9cd6019b04"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
