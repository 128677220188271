exports.onInitialClientRender = async () => {
  if (window.location.pathname === '/') {

    let introImagePlaceholder = document.querySelector('.intro-placeholder')

    const imagesList = [...Array(18).keys()]

    // fetch images
    let results = await Promise.all(

      imagesList.map((_, i) => {

        return fetch(`/${i+1}.jpg`, {
          mode: 'no-cors'
        })
          .then(res => res.blob())
      })
    )

    let blobPromises = []
    results.forEach(blob => {
      
      let blobpromise = new Promise((resolve, reject) => {

        let reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result) 
        }
        reader.readAsDataURL(blob)

      })

      blobPromises.push(blobpromise)
    })

    const res = await Promise.all(blobPromises)

    let i = 0

    let interv = setInterval(function() {
      introImagePlaceholder.style.backgroundImage = `url("${res[i++]}")`

      if (i === blobPromises.length) {
        clearInterval(interv)

        document.body.classList.remove('intro-active')
        
      }
    }, 300)
  } else {
    document.body.classList.remove('intro-active')
  }
}